window.onload = function () {
    //faqs
    var faqs = document.querySelectorAll('#faqs .item')
    for (var i = 0; i < faqs.length; i++) {
        var item = faqs[i]
        item.addEventListener('click', function (e) {
            var el = e.target && e.target.closest ? e.target.closest('.item') : null
            el.classList.toggle('open')
        })
    }

    var bgEl = document.getElementById('bg-img')
    var bEl = document.getElementById('b-con')
    var tEl = document.getElementById('t-con')
    var maskEl = document.getElementById('mask')
    var isAni = false
    var status = 'top'
    //动画
    window.bgAni = function () {
        if (isAni) {
            return
        }
        bEl.classList.add('hide')
        tEl.classList.add('hide')
        bgEl.classList.remove('ani-to-b')
        bgEl.classList.remove('ani-to-t')
        maskEl.style.opacity = 0.75
        maskEl.classList.remove('mask-ani')
        // var timeNum = (device.ios() || device.android())?3500:8500
        // 当前为top状态，向下动画
        if (status == 'top') {
            bgEl.style.top = '0px'
            setTimeout(function () {
                bgEl.classList.add('ani-to-b')
                maskEl.classList.add('mask-ani')
                isAni = true
            }, 300)
            setTimeout(function () {
                tEl.classList.remove('hide')
                isAni = false
                status = 'bottom'
            }, 3700)
        } else {
            // 当前为bottom状态，向上动画
            if (device.ios() || device.android()) {
                bgEl.style.top = '-41.37%'
            } else {
                bgEl.style.top = '-900px'
            }
            setTimeout(function () {
                bgEl.classList.add('ani-to-t')
                isAni = true
                maskEl.classList.add('mask-ani')
            }, 300)
            setTimeout(function () {
                bEl.classList.remove('hide')
                isAni = false
                status = 'top'
            }, 3700)
        }
    }
    bEl.classList.remove('hide')
    // window.bgAni()

    //响应键盘
    document.onkeyup = function (e) {
        var key = e.key.toLocaleUpperCase()
        if ((key === 'B' && status === 'top') || (key === 'T' && status === 'bottom')) {
            window.bgAni()
        } else if (key === 'ENTER') {
            window.location.href = 'https://nft.tanglepay.com/nftEntry.html'
        }
    }
}
